<template>
  <WorkspaceLayout>
    <UDashboardPanel grow>
      <UDashboardNavbar title="User Profile" />
      <UDashboardToolbar class="py-0 px-1.5 overflow-x-auto">
        <UHorizontalNavigation :links="items" />
      </UDashboardToolbar>
      <slot />
    </UDashboardPanel>
  </WorkspaceLayout>
</template>

<script setup lang="ts">
import WorkspaceLayout from './workspace.vue'
import { profileBaseUrl } from '#auth/utils'

const { currentWorkspace } = useWorkspaceSharedState()

const baseUrl = profileBaseUrl(currentWorkspace.value.handle)
const items = [
  {
    label: 'Profile',
    icon: 'i-heroicons-user-circle',
    to: `${baseUrl}/settings`,
  },
  {
    label: 'Security',
    icon: 'i-heroicons-lock-closed',
    to: `${baseUrl}/security`,
  },
]
</script>
